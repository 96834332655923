import styled from "styled-components"

export const Form = styled.div`
  form.hs-form input,
  form.hs-form select,
  form.hs-form textarea {
    width: 100% !important;
    padding: 8px;
    background-color: ${props => props.theme.color.blueLight};
    border: 0;
    border-radius: 0.5rem;
  }

  form.hs-form input,
  form.hs-form select {
    height: 45px;
  }

  form.hs-form label {
    color: ${props => props.theme.color.blue400};
    font-weight: 600;
    font-size: 0.85rem;
  }

  form.hs-form fieldset.form-columns-2,
  form.hs-form fieldset.form-columns-1 {
    margin-bottom: 0.5rem;
    max-width: 100%;
  }

  form.hs-form ul.hs-error-msgs {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  form.hs-form ul.hs-error-msgs label {
    color: #fb6987;
    font-size: 0.75rem;
    line-height: 1;
  }

  form.hs-form input.hs-button.primary.large {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    padding: 1rem 1.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    outline: 0;
    border: 0;
    border-radius: 2rem;
    transition: background-color 0.2s linear;
    width: auto !important;
    background-color: ${props => props.theme.color.blue600};
    margin: 0.5rem 0;
  }

  form.hs-form .actions {
    text-align: right;
  }

  form.hs-form fieldset.form-columns-2 > div {
    width: 50% !important;
  }

  form.hs-form fieldset.form-columns-2 > div,
  form.hs-form fieldset.form-columns-1 > div {
    padding: 0.5rem;
  }
`
